<template>
  <div class="productList common-page">
    <PocCardTable>
      <template slot="headerTitle">
        <span class="font21 colorB">{{ $t('dc:用户组管理') }}</span>
        <span class="padRL6 font21">/</span>
        <span class="font21">{{ $t('dc:产品列表') }}</span>
      </template>
      <template slot="headerGroup">
        <el-input
          v-model="pageConfig.keyword"
          style="width: 240px; margin-right: 10px"
          :placeholder="$t('dc:请输入产品名称')"
          :clearable="true"
          @keyup.enter.native="queryApi"
          @clear="queryApi"
        >
          <el-button
            class="btn-black"
            type="info"
            slot="append"
            @click="queryApi"
            icon="el-icon-search"
          ></el-button>
        </el-input>
        <el-button type="info" @click="addProduct">{{ $t('dc:新增产品') }}</el-button>
      </template>
      <PocTable
        v-loading="loading"
        :data="tableData"
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        :border="false"
        @size-change="pageSizeChange"
        @page-current-change="pageChange"
      >
        <el-table-column
          v-for="item in columnOptions"
          :key="item.key"
          :prop="item.chlidren ? null : item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sorter="item.sortable"
          :width="item.key === 'no' ? 60 : null"
          :min-width="item.width"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row[item.key] || '-' }}</span>
          </template>
          <el-table-column
            v-for="chlid in item.chlidren"
            v-if="chlid.visible"
            :key="chlid.key"
            :prop="chlid.key"
            :label="chlid.name"
            :fixed="chlid.fixed"
            :sorter="chlid.sortable"
            :min-width="item.width"
            show-overflow-tooltip
          >
          </el-table-column>
        </el-table-column>

        <el-table-column :label="$t('dc:操作')" width="150" fixed="right" align="center">
          <template slot-scope="scope">
            <div class="action-btn-group">
              <el-tooltip effect="dark" :content="$t('dc:编辑')" placement="top">
                <i
                  class="poc-icon-info poc-size12 iconfont icon-oneui-contract-edit padding12 nicon"
                  @click="showEdit(scope.row)"
                ></i>
              </el-tooltip>
              <el-tooltip effect="dark" :content="$t('dc:跳转至权限列表')" placement="top">
                <i
                  class="poc-icon-info poc-size12 iconfont icon-oneui-contract-permission-set padding12 nicon"
                  @click="selectAccess(scope.row)"
                ></i>
              </el-tooltip>

              <el-tooltip effect="dark" :content="$t('dc:跳转至公告列表')" placement="top">
                <i
                  class="poc-icon-info poc-size12 iconfont icon-oneui-anncounment padding12 nicon"
                  @click="goAnnouncementList(scope.row)"
                ></i>
              </el-tooltip>

              <el-tooltip
                v-if="scope.row.projectStatus === 0"
                effect="dark"
                :content="$t('dc:禁用')"
                placement="top"
              >
                <i
                  class="poc-icon-info poc-size12 iconfont icon-oneui-contract-disable padding12 nicon"
                  @click="forbidAccess(scope.row)"
                ></i>
              </el-tooltip>

              <el-tooltip
                v-if="scope.row.projectStatus === 3"
                effect="dark"
                :content="$t('dc:恢复')"
                placement="top"
              >
                <i
                  class="poc-icon-info poc-size12 iconfont icon-oneui-reload padding12 nicon"
                  @click="recoverAccess(scope.row)"
                ></i>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </PocTable>
    </PocCardTable>
    <productDialog
      v-if="showDialog"
      :visible.sync="showDialog"
      :title="productTitle"
      :rowData="rowData"
      @queryApi="queryApi"
    ></productDialog>
    <Forbid4Delete
      v-if="show4Dialog"
      :visible.sync="show4Dialog"
      :title="title"
      :content="content"
      @confrom="confrom"
    ></Forbid4Delete>
  </div>
</template>

<script>
import productDialog from '@/views/dialog/productDialog'
import Forbid4Delete from '@/views/dialog/usuallyDialog'

export default {
  name: 'productList',
  mixins: [$PCommon.TableMixin],
  components: {
    productDialog,
    Forbid4Delete,
  },
  data() {
    return {
      pageConfig: {
        keyword: '',
      },
      loading: false,
      showDialog: false,
      show4Dialog: false,
      productType: 0,
      title: this.$t('dc:权限'),
      content: this.$t('dc:您确认选择该产品吗?'),
      tableData: [],
      updataParams: {
        projectId: '',
        projectStatus: '',
      },
      productTitle: this.$t('dc:新增产品'),
      rowData: {},
    }
  },
  computed: {
    columnConfig() {
      return [
        {
          key: 'projectId',
          name: 'No',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: false, // 固定列的位置(left, right)
          width: 60, // 默认宽度，像素
        },
        {
          key: 'projectName',
          name: this.$t('dc:产品名称'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false,
          width: 120,
        },
        // {
        //   key: 'projectStatusName',
        //   name: this.$t('dc:状态'),
        //   dataType: 'string',
        //   visible: true,
        //   sortable: false,
        //   fixed: null,
        //   width: 120,
        // },
        {
          key: 'owner',
          name: this.$t('dc:归属'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 120,
        },
        {
          key: 'projectKey',
          name: this.$t('dc:编码'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 120,
        },
        {
          key: 'url',
          name: this.$t('dc:URL'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 180,
        },
      ]
    },
    columnOptions() {
      return this.columnConfig.filter((n) => n.visible)
    },
  },
  mounted() {
    this.initEvent()
  },
  methods: {
    initEvent() {
      this.queryApi()
    },
    async queryApi() {
      this.loading = true
      let { status, data } = await $Api.project.getAppList(this.pageConfig)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.tableData = data.list
        this.pageConfig.total = data.total
      }
      this.loading = false
    },
    addProduct() {
      this.rowData = {}
      this.productTitle = this.$t('dc:新增产品')
      this.showDialog = true
    },
    showEdit(row) {
      console.log(row)
      this.productTitle = this.$t('dc:编辑产品')
      this.rowData = row
      this.showDialog = true
    },
    selectAccess(item) {
      this.productType = 0
      this.updataParams.projectStatus = 0
      this.updataParams.projectId = item.projectId
      this.$router.push({
        name: 'OperationAccess.AccessList',
        query: { id: this.updataParams.projectId },
      })
    },
    goAnnouncementList(row) {
      this.$router.push({
        name: 'OperationAccess.AnnouncementList',
        query: { projectId: row.projectId },
      })
    },
    forbidAccess(item) {
      this.title = this.$t('dc:禁止')
      this.content = this.$t('dc:您确认禁止该产品吗?')
      this.productType = 1
      this.updataParams.projectStatus = 3
      this.updataParams.projectId = item.projectId
      this.show4Dialog = true
    },
    recoverAccess(item) {
      this.title = this.$t('dc:恢复')
      this.content = this.$t('dc:您确认恢复该产品吗?')
      this.productType = 1
      this.updataParams.projectStatus = 0
      this.updataParams.projectId = item.projectId
      this.show4Dialog = true
    },
    async confrom() {
      if (this.productType !== 0) {
        let { status, msg } = await $Api.project.updateStatus(this.updataParams)
        if (status === this.$RESPONSE_STATUS.SUCCESS) {
          this.$message({
            showClose: true,
            message: msg || this.$t('dc:成功'),
            type: 'success',
          })
          this.queryApi()
        }
      }
      this.show4Dialog = false
    },
  },
}
</script>

<style lang="scss">
.productList .poc-card-table .pcth-query-box .pcth-queryName {
  min-width: 0;
}
.productList .padding12 {
  padding: 0 12px;
}
</style>
