export const productDialogMixins = {
  computed: {
    productTypeOptions() {
      return [
        {
          name: 'Login Only',
          value: 0,
        },
        {
          name: 'MAKE IT RIGHT',
          value: 1,
        },
        {
          name: 'MAKE IT MAGIC',
          value: 2,
        },
        {
          name: 'MAKE IT WORK',
          value: 3,
        },
        {
          name: 'Team APP',
          value: 4,
        },
      ]
    },
  },
}
